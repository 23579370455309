import { computed, Injectable, signal } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatHelpDeskService {
  protected readonly _isShown = signal<boolean>(false);
  readonly isShown = computed(() => this._isShown());
  protected readonly _showOptions = signal<boolean>(true);
  readonly showOptions = computed(() => this._isShown());
  helpLink = environment.helpLink;
  constructor() {}

  reload() {
    this.closeOptions();
    this.show();
  }
  show() {
    this._isShown.set(true);
  }
  hide() {
    this._isShown.set(false);
  }
  hideOptions() {
    this._showOptions.set(false);
  }
  toggle() {
    this._isShown.set(!this._isShown());
  }

  closeOptions() {
    this._showOptions.set(false);
  }
  openOptions() {
    this._showOptions.set(true);
  }
  toggleOptions() {
    this._showOptions.set(!this._showOptions());
  }
}
